import { useRouter } from 'next/router';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import LaunchIcon from '@material-ui/icons/Launch';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { red, green, amber } from '@material-ui/core/colors';
import {
  format,
  parseISO,
  formatDistanceToNow,
  isFuture,
  isPast,
  differenceInDays,
} from 'date-fns';
import useNotifications from '../hooks/useNotifications';
import LoadingSpinner from './LoadingSpinner';
import getYearName from '../utils/getYearName';

function deadlineColor(date) {
  const today = new Date();
  const deadline = new Date(date);
  const deadlinePassed = isPast(deadline);
  const deadlineApproaching = differenceInDays(deadline, today) <= 7;
  if (deadlinePassed) return red[800];
  if (deadlineApproaching) return amber[900];
  return green[800];
}

function makeCaptureCombinations(capture) {
  const combinations = capture.combinations?.reduce((acc, current) => {
    if (acc.has(current.subject.id)) {
      const subject = acc.get(current.subject.id);
      acc.set(current.subject.id, {
        ...subject,
        systems: [...subject.systems, current.scoreSystem.name],
      });
    } else {
      acc.set(current.subject.id, {
        subject: current.subject.name,
        systems: [current.scoreSystem.name],
      });
    }
    return acc;
  }, new Map());
  return (
    [...combinations.values()]
      .map(
        (combination) =>
          `${combination.subject} (${combination.systems.join(', ')})`
      )
      .join(', ') || 'None'
  );
}

function NotificationsEmptyState() {
  return (
    <div style={{ marginTop: 30, textAlign: 'center' }}>
      <ErrorOutlineIcon style={{ width: 50, height: 50 }} />
      <Typography component="h2" variant="h5" style={{ fontWeight: 900 }}>
        Your school hasn&apos;t sent any notifications
      </Typography>
      <Typography>
        When they do, those notifications will show up here.
      </Typography>
    </div>
  );
}

export default function Notifications() {
  const router = useRouter();
  const { data: notifications, isLoading, isError, error } = useNotifications();

  if (isLoading) return <LoadingSpinner />;

  if (isError) return <span>Error: {error.message}</span>;

  if (!isLoading && !notifications.length) return <NotificationsEmptyState />;

  return (
    <TableContainer component={Paper} variant="outlined">
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Capture</TableCell>
            <TableCell>Cohort</TableCell>
            <TableCell>Aspects &amp; Score Systems</TableCell>
            <TableCell>Deadline</TableCell>
            <TableCell>Completed?</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {notifications?.map((notification) => {
            const deadline = new Date(notification.dataCapture.deadlineDate);
            const timeRemaining = formatDistanceToNow(deadline, {
              addSuffix: true,
            });
            const deadlineDate = format(
              parseISO(notification.dataCapture.deadlineDate),
              'dd/MM/yyyy'
            );
            const isActive = isFuture(deadline);
            const color = deadlineColor(deadline);
            return (
              <TableRow key={notification.id}>
                <TableCell>{notification.dataCapture.title}</TableCell>
                <TableCell>
                  {notification.class?.title ||
                    notification.form?.name ||
                    getYearName(notification.year)}
                </TableCell>
                <TableCell>
                  {makeCaptureCombinations(notification.dataCapture)}
                </TableCell>
                <TableCell>
                  {deadlineDate}{' '}
                  <Chip
                    variant="outlined"
                    style={{
                      color,
                      borderColor: color,
                      marginLeft: 5,
                    }}
                    size="small"
                    icon={
                      isActive ? (
                        <EventAvailableIcon style={{ marginLeft: 5, color }} />
                      ) : (
                        <EventBusyIcon style={{ marginLeft: 5, color }} />
                      )
                    }
                    label={timeRemaining}
                  />
                </TableCell>
                <TableCell>
                  {notification.completed ? (
                    <CheckIcon
                      style={{
                        color: green[800],
                        verticalAlign: 'middle',
                      }}
                    />
                  ) : (
                    <CloseIcon
                      style={{
                        color: red[800],
                        verticalAlign: 'middle',
                      }}
                    />
                  )}
                </TableCell>
                <TableCell>
                  <Button
                    fullWidth
                    color="primary"
                    size="small"
                    endIcon={<LaunchIcon />}
                    onClick={() =>
                      router.push(`/submissions/${notification.id}`)
                    }
                  >
                    View
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
