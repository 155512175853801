const colours = {
    powder: {
        red: '#E06262',
        blue: '#76C1E8',
        green: '#8EE0A5',
        yellow: '#F4F182'
    }
}

export function* powderClrGenerator() {
    let i = 0;
    const powderColours = Object.values(colours.powder);
    while (true) {
        yield powderColours[i];
        ++i;
    }
};

export default colours;