import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import ApiError from '../utils/ApiError';

async function fetchNotifications() {
  const response = await fetch('/api/notifications');
  const responseBody = await response.json();
  if (!response.ok) throw new ApiError(responseBody.message, response.status);
  return responseBody;
}

export default function useNotifications() {
  return useQuery('notifications', fetchNotifications, {
    onError(error) {
      toast(error.message, { type: 'error' });
    },
  });
}
