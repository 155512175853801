import { useQuery } from 'react-query';
import ApiError from '../utils/ApiError';

async function fetchAuthedUser() {
  const response = await fetch('/api/users/me');
  const responseBody = await response.json();
  if (!response.ok) throw new ApiError(responseBody.message, response.status);
  return responseBody;
}

export default function useAuthedUser() {
  return useQuery('me', fetchAuthedUser, {
    refetchOnWindowFocus: false,
  });
}
