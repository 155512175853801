import { defaultErrorMessage } from '../constants';

class ApiError extends Error {
  constructor(message = defaultErrorMessage, status = 400) {
    super(message);
    this.status = status;
  }
}

export default ApiError;
