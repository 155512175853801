import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    margin: '30px auto',
    textAlign: 'center',
  },
  loadingText: {
    marginTop: 25,
  },
});

export default function LoadingSpinner({
  size = 100,
  loadingText,
  secondaryLoadingText,
}) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <CircularProgress size={size} />
      {loadingText && (
        <Typography variant="h6" className={classes.loadingText}>
          {loadingText}
        </Typography>
      )}
      {secondaryLoadingText && (
        <Typography variant="body1" color="textSecondary">
          {secondaryLoadingText}
        </Typography>
      )}
    </div>
  );
}
